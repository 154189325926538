import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Button from '../components/Button'
import Content from '../components/Content'
import Root from '../layouts/Root'

import config from '../config'

export default ({ data }) => {
  const { frontmatter, html, excerpt } = data.markdownRemark

  return (
    <Root>
      {/* prettier-ignore */}
      <Helmet>
        <title>{`${frontmatter.meta_title || frontmatter.title} | ${config.title}`}</title>
        <meta name="description" content={frontmatter.meta_description || excerpt} />
        <meta property="og:title" content={`${frontmatter.meta_title || frontmatter.title} | ${config.title}`} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={frontmatter.meta_description || excerpt} />
        {frontmatter.cover && <meta property="og:image" content={config.siteUrl + frontmatter.cover.childImageSharp.fluid.src} />}
        {frontmatter.cover && <meta property="og:image:height" content={frontmatter.cover.childImageSharp.original.height} />}
        {frontmatter.cover && <meta property="og:image:width" content={frontmatter.cover.childImageSharp.original.width} />}
      </Helmet>

      <main>
        <Content
          title={frontmatter.title}
          // prettier-ignore
          image={frontmatter.cover ? frontmatter.cover.childImageSharp.fluid : null}
          // prettier-ignore
          image_alt={frontmatter.cover_alt || frontmatter.title}
          // prettier-ignore
          cta={() => (
          <>
            <Button color="confirm" onClick={() => window.history.go(-1)} size="large">Terug</Button>
            <Button type="link" to="/diensten/" size="large">Bekijk alle diensten</Button>
          </>
        )}
        >
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </Content>
      </main>
    </Root>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        meta_title
        meta_description
        cover_alt
        cover {
          childImageSharp {
            original {
              height
              width
            }
            fluid(maxWidth: 800, maxHeight: 600, quality: 75, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
